import React from 'react';
import withLayout from '@/base/containers/withLayout';
import Link from '@/components/elements/Link';
import AboutPageHero from '@/components/sections/AboutPageHero';
import loadable from '@loadable/component';
import type { AboutPageTemplateProps, AboutPageTypes } from './AboutPage.types';
import * as styles from './AboutPage.module.scss';

const SectionRichText = loadable(() => import('../../sections/SectionRichText'));
const CustomerLogoSection = loadable(() => import('../../sections/CustomerLogoSection'));
const AboutPageCareersSection = loadable(() => import('../../sections/AboutPageCareersSection'));
const RelatedPosts = loadable(() => import('../../modules/RelatedPosts'));

export function AboutPage({ content }: StoryblokEntry<AboutPageTypes> & { location: Location }) {
  const {
    body,
    card_body,
    image,
    upper_table_cells,
    lower_table_cells,
    customers_body,
    logos,
    primary_image,
    secondary_image,
    careers_body,
    posts_title,
    posts_type,
    posts_link,
    posts_link_label,
  } = content;
  return (
    <>
      <AboutPageHero
        body={body}
        cardBody={card_body}
        image={image}
        table={{ upperCells: upper_table_cells, lowerCells: lower_table_cells }}
      />
      {customers_body && (
        <div className={`container ${styles.logosBody} `}>
          <div className="col-md-10 m-0-auto">
            <SectionRichText body={customers_body} className={styles} />
          </div>
        </div>
      )}
      <CustomerLogoSection top_spacing="none" bottom_spacing="none" logos={logos} />
      <AboutPageCareersSection mainImage={primary_image} secondaryImage={secondary_image} body={careers_body} />
      <RelatedPosts slug={posts_type} title={posts_title} bottomSpacing="sm" />
      <div className="container row m-0-auto">
        <Link type="internal" href={posts_link.cached_url} wrapperClassName="m-0-auto" display_arrow>
          {posts_link_label}
        </Link>
      </div>
    </>
  );
}

export default function AboutPageTemplate({ pageContext, location }: AboutPageTemplateProps) {
  return withLayout(AboutPage, { ...pageContext, location });
}
