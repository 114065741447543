import React from 'react';
import { AboutTableSectionTypes } from './AboutTableSection.types';
import * as styles from './style.module.scss';

export default function AboutTableSection({ upperCells, lowerCells }: AboutTableSectionTypes) {
  if (!upperCells || !lowerCells) return null;
  return (
    <div className={`justify-start md-justify-start ${styles.section}`}>
      <div className={styles.card}>
        <ul className={styles.cardUpper}>
          {upperCells.map(upperCell => (
            <li key={upperCell._uid}>
              <p className={`${styles.textBig} ${styles.dividerAfter}`}>{upperCell.value}</p>
              <p className={styles.textSmall}>{upperCell.description}</p>
            </li>
          ))}
        </ul>

        <ul className={styles.cardLower}>
          {lowerCells.map(lowerCell => (
            <li key={lowerCell._uid}>
              <p className={`${styles.textBig} ${styles.seperatorBefore}`}>{lowerCell.value}</p>
              <p className={styles.textSmall}>{lowerCell.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
