// extracted by mini-css-extract-plugin
export var card = "AboutPageHero-module--card--52d84";
export var cardWrapper = "AboutPageHero-module--cardWrapper--c21b8";
export var container = "AboutPageHero-module--container--c3e4b";
export var headingH1 = "AboutPageHero-module--headingH1--32bb5";
export var opacityEnter = "AboutPageHero-module--opacityEnter--a134e";
export var opacityExit = "AboutPageHero-module--opacityExit--53282";
export var paragraph = "AboutPageHero-module--paragraph--cfb5a";
export var rollDown = "AboutPageHero-module--rollDown--1647d";
export var rollUp = "AboutPageHero-module--rollUp--865aa";
export var root = "AboutPageHero-module--root--33f93";
export var slideInDown = "AboutPageHero-module--slideInDown--4617e";
export var slideOutUp = "AboutPageHero-module--slideOutUp--400d3";
export var splashEnter = "AboutPageHero-module--splashEnter--dff10";
export var splashExit = "AboutPageHero-module--splashExit--12f09";
export var textWrapper = "AboutPageHero-module--textWrapper--35e1f";