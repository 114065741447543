// extracted by mini-css-extract-plugin
export var headingH2 = "CardRichText-module--headingH2--a9035";
export var opacityEnter = "CardRichText-module--opacityEnter--11a27";
export var opacityExit = "CardRichText-module--opacityExit--19a84";
export var paragraph = "CardRichText-module--paragraph--5043f";
export var rollDown = "CardRichText-module--rollDown--acbdb";
export var rollUp = "CardRichText-module--rollUp--ede46";
export var slideInDown = "CardRichText-module--slideInDown--327c3";
export var slideOutUp = "CardRichText-module--slideOutUp--354b2";
export var splashEnter = "CardRichText-module--splashEnter--c33aa";
export var splashExit = "CardRichText-module--splashExit--86190";