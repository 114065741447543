import React from 'react';
import Image from '@/components/elements/Image';
import SectionRichText from '@/components/sections/SectionRichText/SectionRichText';
import AboutTableSection from '../AboutTableSection';
import { AboutPageHeroTypes } from './AboutPageHero.types';
import * as styles from './AboutPageHero.module.scss';
import * as cardStyles from './CardRichText.module.scss';

export default function AboutPageHero({ body, cardBody, image, table }: AboutPageHeroTypes) {
  return (
    <>
      <div className={styles.container}>
        <div className="row m-0">
          <div className={styles.textWrapper}>
            <SectionRichText body={body} className={styles} />
          </div>
          <div className="col-lg-6 p-0">
            <Image data={image} fluid />
          </div>
        </div>
        <div className="flex row m-0 flex-col-reverse justify-center md-flex-row md-justify-start">
          <div className="col-md-6 p-0 md-block">
            <AboutTableSection {...table} />
          </div>
          <div className={` col-md-6 ${styles.cardWrapper}`}>
            <div className={styles.card}>
              <SectionRichText body={cardBody} className={cardStyles} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
