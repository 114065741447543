// extracted by mini-css-extract-plugin
export var card = "style-module--card--447af";
export var cardLower = "style-module--cardLower--8add5";
export var cardUpper = "style-module--cardUpper--f0d41";
export var dividerAfter = "style-module--dividerAfter--7e909";
export var opacityEnter = "style-module--opacityEnter--d9b4b";
export var opacityExit = "style-module--opacityExit--55d8c";
export var padded = "style-module--padded--5f432";
export var rollDown = "style-module--rollDown--0186a";
export var rollUp = "style-module--rollUp--5fa44";
export var section = "style-module--section--1ad39";
export var seperator = "style-module--seperator--ea421";
export var seperatorBefore = "style-module--seperatorBefore--5ef96";
export var slideInDown = "style-module--slideInDown--f240d";
export var slideOutUp = "style-module--slideOutUp--7d471";
export var splashEnter = "style-module--splashEnter--b29d5";
export var splashExit = "style-module--splashExit--d45dc";
export var textBig = "style-module--textBig--4af0a";
export var textSmall = "style-module--textSmall--8d5fd";