// extracted by mini-css-extract-plugin
export var headingH2 = "AboutPage-module--headingH2--b7d84";
export var logosBody = "AboutPage-module--logosBody--59f25";
export var opacityEnter = "AboutPage-module--opacityEnter--13ce6";
export var opacityExit = "AboutPage-module--opacityExit--d04d9";
export var paragraph = "AboutPage-module--paragraph--1a223";
export var rollDown = "AboutPage-module--rollDown--9f798";
export var rollUp = "AboutPage-module--rollUp--9a5ab";
export var slideInDown = "AboutPage-module--slideInDown--8f4d1";
export var slideOutUp = "AboutPage-module--slideOutUp--1dc8e";
export var splashEnter = "AboutPage-module--splashEnter--7ad7a";
export var splashExit = "AboutPage-module--splashExit--d02e6";